import React, { Component, createRef } from 'react'
import { staticAssetsPrefix } from '../../lib/utils'
import Play from './Play'
import { maxScreenSize, minScreenSize } from '../../lib/theme'
import { Modal } from 'antd';

export default class LandingMainImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            showVideo: false,
        };
        this.videoRef = createRef();
        this.player = null;
    }

    openModal = () => {
        this.setState({ isModalOpen: true }, () => {
            setTimeout(() => {
                this.playFullscreenVideoMain();
                // this.player?.play(); // Auto-play after modal opens
            }, 300);
        });
    };

    closeModal = (e) => {
        e.stopPropagation();
        console.dir('closeModal');
        console.dir('closeModal1');
        this.player.stop(); // Pause video when modal closes
        console.dir('closeModal2');
        // this.player = null;

        this.setState({ isModalOpen: false, showVideo: false }, () => {
        });
        // this.setState({ isModalOpen: false });
    };

    playFullscreenVideoMain = async () => {
        if (this.player == null) {
            await this.initPlyr();
        }
        await this.playFullscreenVideo();

        // const video = this.videoRef.current;
        // if (video) {
        //     video.play();
        //     if (video.requestFullscreen) {
        //         video.requestFullscreen();
        //     } else if (video.webkitRequestFullscreen) {
        //         video.webkitRequestFullscreen();
        //     } else if (video.msRequestFullscreen) {
        //         video.msRequestFullscreen();
        //     }
        // }
    };

    initPlyr = async () => {
        // const { thumbnailUrl, videoUrl } = this.props;
        const video = this.videoRef.current;
        // const hlsUrl = videoUrl; // Replace with your HLS video source
        // const mp4Url = bookVideoFromRawIdMp4Fallback(videoUrl); // MP4 fallback

        // Check if the browser supports native HLS
        if (video.canPlayType("application/vnd.apple.mpegurl")) {
            video.src = 'https://vz-9338bb95-d77.b-cdn.net/6b5b959a-5283-442e-82d8-57b3a002feb6/playlist.m3u8';
        } else {
            video.src = 'https://vz-9338bb95-d77.b-cdn.net/6b5b959a-5283-442e-82d8-57b3a002feb6/play_720p.mp4'; // Fallback to MP4
        }
        console.dir('init plyr');

        // Initialize Plyr
        const Plyr = (await import('plyr')).default;
        if (this.player == null) {
            this.player = new Plyr(video, {
                controls: ["play", "progress", "current-time", "mute", "fullscreen"],
            });
        }
    };

    playFullscreenVideo = async () => {
        const video = this.videoRef.current;
        if (this.player) {
            await this.player.play(); // Start playing
            this.setState({ showVideo: true });

            // // Ensure fullscreen works across browsers
            // if (document.fullscreenEnabled || document.webkitFullscreenEnabled) {
            //     if (video.requestFullscreen) {
            //         await video.requestFullscreen();
            //     } else if (video.webkitRequestFullscreen) {
            //         await video.webkitRequestFullscreen();
            //     } else if (video.msRequestFullscreen) {
            //         await video.msRequestFullscreen();
            //     }
            // }
        }
    };

    render() {
        const { showVideo, isModalOpen } = this.state;
        console.dir(isModalOpen);
        return (
            <div>
                <div onClick={this.openModal} style={{ position: 'relative', cursor: 'pointer' }}>
                    {/* <div className='desktop-image' style={{ width: '80%', marginLeft: '40px' }}>
                                                <picture>
                                                    <source media="(max-width: 768px)" sizes="1px" srcSet={`data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA71w`} />
                                                    <img style={{ maxWidth: '90%', borderRadius: '16px' }} className='' src={`${staticAssetsPrefix}/static/images/main-page/LandingDesktop.png`} alt='Descktop app of Accelerated' />
                                                </picture>
                                            </div> */}
                    <div className='mobile-image' style={{}}>
                        <picture>
                            <source media="(max-width: 768px)" sizes="1px" srcSet={`${staticAssetsPrefix}/static/images/main-page/LandingThreePhones2Video2V2.png`} />
                            <img style={{ width: '100%' }} src={`${staticAssetsPrefix}/static/images/main-page/LandingLarge2VideoV2.png`} alt="Mobile app of Accelerated" />
                        </picture>
                    </div>
                    <div className='play-btn'>
                        <Play />
                    </div>
                </div>
                <Modal
                    open={this.state.isModalOpen}
                    onCancel={this.closeModal}
                    footer={null}
                    centered
                    width="100vw"
                    className='video-modal'
                    bodyStyle={{
                        backgroundColor: "rgba(0, 0, 0, 1)", // Semi-transparent background
                        padding: 0,
                        height: "100vh", // Full screen height
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="flex justify-center items-center w-full h-full">
                        <video ref={this.videoRef}
                            className='hidden'
                            style={{
                                objectFit: "contain", // Prevents overflow, keeps aspect ratio
                                maxWidth: "100vw", // Ensures video doesn’t exceed screen width
                                // width: "56.25vh",
                                height: "100vh", // Ensures video doesn’t exceed screen height
                                maxHeight: "100vh", // Ensures video doesn’t exceed screen height
                            }}
                            playsInline controls />
                    </div>
                </Modal>
                <style jsx>
                    {`
                    .hidden {
                        display: ${showVideo ? 'block' : 'none'};
                    }
                        :global(.video-modal .ant-modal-body) {
                        background-color: rgba(0, 0, 0, 1) !important;
                        border-radius: 0 !important;
                        }
                        :global(.ant-modal-close-x) {
                        color: white !important;
                        }


                        .play-btn {
                            position: absolute;
                            // bottom: 0;
                            // top: 60%;
                            // left: 2%;
                                left: 12%;
                            bottom: 38%;
                        }
                    .mobile-image {
                            // max-width: 274px;
                            position: relative;
                        }
                             @media ${minScreenSize.tablet} {
                                                        .mobile-image {
                                                            // max-width: 400px;
                                                            // position: absolute;
                                                            // top: 80px; 
                                                            // left: -80px; 
                                                            width: 100%;
                                                            margin: auto;
                                                        }
                                                        .title-align {
                                                            text-align: left;
                                                        }
                                                        .btn-width {
                                                            width: 420px;
                                                        }
                                                        .app-stores {
                                                            // padding-left: 44px;
                                                            text-align: left;
                                                        }
                                                    }
                                                         @media ${maxScreenSize.tablet} {
                                                                                     .play-btn {
                                                                                         position: absolute;
                                                                                         // bottom: 0;
                                                                                         // left: 50%;
                                                                                         // top: 60%;
                                                                                         bottom: 10%;
                                                                                         left: 50%;
                                                                                         transform: translate(-50%, -50%);
                                                                                     }
                                                                                      .mobile-image {
                                                                                        //   opacity: 0.95;
                                                                                        //   top: -200px;
                                                                                        max-width: 100vw;
                                                                                      }
                                                                                        .mobile-image img {
                                                                                            // width: 150% !important;
                                                                                            // margin-left: -25%;   
                                                                                        }
                                                                                }
                                @media ${minScreenSize.laptop} {
                                                            .main-title{
                                                                // top: 40%;
                                                                padding: 0 8%;
                                                                padding-top: 80px;
                                                                max-width: 1400px;
                                                            }
                                                            .mobile-image {
                                                                // max-width: 392px;
                                                                // opacity: 0.95;
                                                                // transform: rotate(12deg);
                                                            }
                                                            .desktop-image {
                                                                min-height: 500px;
                                                                opacity: 0.9;
                                                            }
                                
                                                            .btn-width {
                                                                width: 488px;
                                                            }
                                                        }
                                                        @media ${minScreenSize.laptopL} {
                                                            .mobile-image {
                                                                max-width: 600px;
                                                            }
                                                        }
                    `}
                </style>
            </div>
        )
    }
}
