import React from 'react'
import PropTypes from 'prop-types';
import { staticAssetsPrefix } from '../../lib/utils';
import { spacing, maxScreenSize } from '../../lib/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const iconHeight = '50px';

function FeatureCard({ id, title, message, icon, iconColor, iconBg }) {
    return (
        <>
            <div className='app-card cssanimation sequence fadeInBottom'>
                {id <= 3 && <img src={`${staticAssetsPrefix}/static/images/main-page/${icon}`} />}
                {/* <div className='iconBg'>
                    {id == 7 ? <span className='fa-stack' style={{ position: 'relative' }}>
                        <FontAwesomeIcon icon='ad' style={{ width: '1.5rem', fontSize: '1.5rem', color: iconColor }} />
                        <p className="fa-stack-1x" style={{ position: 'absolute', top: '0' }}>
                            <FontAwesomeIcon icon='slash' style={{ width: '1.5rem', fontSize: '1.5rem' }} /></p>
                    </span> :
                        id == 1 ? <img style={{ width: '1.25rem', transform: 'translate(0, -4px)' }} src={`${staticAssetsPrefix}/static/images/main-page/multiple-devices.png`} /> :
                            <FontAwesomeIcon icon={icon} style={{ width: '1.5rem', paddingBottom: '4px', fontSize: id == 4 ? '1.05rem' : '1.25rem', color: iconColor }} />
                    }
                </div> */}
                <div className={id <= 3 ? 'content-1-3' : 'content'}>
                    <h3 className='title quicksand dark'>{title}</h3>
                    <p className='message quicksand'>{message}</p>
                    {id == 3 && <a href='https://medium.com/illumination/accelerated-vs-blinkist-vs-headway-best-learning-app-2024-fb1d2eb1a4f3'
                        target='_blank' rel="noopener noreferrer" style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Accelerated vs. Blinkist vs. Headway</a>}
                </div>
            </div>
            <style jsx>
                {`
                    img {
                        max-width: min(400px, 100%);
                    }
                    .app-card{
                        // width: 180px;
                        text-align: center;
                        background-color: #fff;
                        border-radius: ${spacing()};
                        padding: ${spacing()};
                        padding-top: ${spacing('lg')};
                        margin: ${spacing()} 0.75em;
                        // box-shadow: 0 8px 8px rgba(0,0,0,0.30), 0 4px 4px rgba(0,0,0,0.22);
                        box-shadow: 2px 22px 43px rgba(171, 171, 180, 0.18);
                    }
                    .content-1-3 {
                        padding: 0 1em;
                        min-height: 200px;
                    }
                    .content {
                        padding: 0 1em;
                        min-height: 140px;
                    }
                    .cssanimation, .cssanimation span {
                        animation-duration: 1s;
                        animation-fill-mode: both;
                    }
                    .cssanimation span { display: inline-block }

                    .iconBg {
                        text-align: center;
                        background-color: ${iconBg};
                        width: 50%;
                        margin-left: 25%;
                        // height: 40px;
                        height: ${iconHeight};
                        width: ${iconHeight};
                        line-height: calc(${iconHeight} + 10px);
                        text-align: center;
                        border-radius: 50%;
                        margin: 0 auto;
                    }

                    .title {
                        text-align: center;
                        font-size: 1.5em;
                        font-weight: 700;
                        margin-top: ${spacing('sm')};
                        margin-bottom: ${spacing('sm')};
                    }
                    .message {
                        text-align: center;
                    }
                    @media ${maxScreenSize.tablet} {
                        .app-card{
                            // width: 37vw;
                            // height: 240px;
                            padding: ${spacing('xs')};
                        }

                        .content-1-3 {
                            padding: 0 1em;
                            min-height: 200px;
                        }
                        .content {
                            padding: 0 1em;
                            min-height: 140px;
                        }
                    }
                    @media ${maxScreenSize.laptop} {
                        .app-card{
                            // width: 140px;
                        }
                        .content-1-3 {
                            min-height: 248px;
                        }
                        .content {
                            min-height: 140px;
                        }
                    }
                `}
            </style>
            {/* <style jsx>
                {`
            img {
              max-width: 200px;
            //   background-color: gray;
                        border-radius: ${spacing('')};
            }
        `}
            </style> */}
        </>
    )
}

FeatureCard.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    message: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconBg: PropTypes.string,
}

export default FeatureCard;