import React, { Component } from 'react'
import { staticAssetsPrefix } from '../../lib/utils'

const size = 80;
export default class Play extends Component {
    render() {
        return (
            <div>
                <div className='image-container'>
                    <img style={{}} src={`${staticAssetsPrefix}/static/images/main-page/play.png`} />

                </div>
                <style>
                    {`
                    /* Container for the image */
.image-container {
  position: relative;
  display: inline-block;
  padding: 10px;
}

/* Image styling */
.image-container img {
  display: block;
  border-radius: 50%;
  width: ${size}px;
  height: ${size}px;
  z-index: 10;
  position: relative;
}

/* Ripple effect using ::before */
.image-container::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${size}px;
  height: ${size}px;
  border-radius: 50%;
  background: rgba(235, 0, 40, 1);
  transform: translate(-50%, -50%);
  z-index: 1;
  animation: ripple 2s infinite;
}

/* Ripple animation */
@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.7;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.75);
    opacity: 0;
  }
}
                    `}
                </style>
            </div>
        )
    }
}
